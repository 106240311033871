import CrossIcon from "@shared/assets/icons/actions/cross.svg";
import LogoIcon from "@shared/assets/icons/logo/logoMenu.svg";
import { Box, Button, Typography } from "@shared/ui";

import css from "./BurgerMenu.module.scss";

interface HeaderSectionProps {
  onClose: () => void;
}

export const HeaderSection = ({ onClose }: HeaderSectionProps) => {
  return (
    <Box
      direction="row"
      alignItems="center"
      justifyContent="between"
      className={css.headerSectionWrapper}
    >
      <Box direction="row" alignItems="center" className="gap-[8px]">
        <LogoIcon className="svg-primary" />
        <Typography fontWeight="bold">UBO</Typography>
      </Box>
      <Button variant="close" aria-label="close" onClick={onClose} className={css.closeButton}>
        <CrossIcon className={css.closeIcon} />
      </Button>
    </Box>
  );
};
