import { Navigation } from "@components/navigation";
import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import {
  useContentActions,
  useIsContentDisabled,
  useIsContentScrolled,
} from "@shared/store/content";
import cn from "classnames";
import { WheelEvent } from "react";
import { Outlet } from "react-router-dom";

import { ScrollButton } from "./components/scrollButton";
import { Header } from "./Header.component.tsx";
import css from "./layout.module.scss";

const HeaderLayout = () => {
  const isTablet = useMediaQuery("Tablet");
  const { onScroll } = useContentActions();
  const isScrolled = useIsContentScrolled();
  const isDisabled = useIsContentDisabled();

  const onWheel = (e: WheelEvent<HTMLElement>) => {
    if (!isTablet && !isDisabled) {
      if (e.deltaY > 0) {
        onScroll(true);
      } else {
        onScroll(false);
      }
    }
  };

  return (
    <div className={css.layout}>
      {!isTablet && <Navigation />}
      <section className={css.center} onWheel={onWheel}>
        <Header />
        <main id="pageWrapper" className={cn(css.page, { [css.scrolledPage]: isScrolled })}>
          <Outlet />
          {!isTablet && <ScrollButton />}
        </main>
      </section>
    </div>
  );
};

export default HeaderLayout;
