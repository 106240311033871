import { withSuspense } from "@shared/lib/hocs/withSuspense.hoc.tsx";
import { createModalHook } from "@shared/ui";
import { lazy } from "react";

import { ClientsEditProps } from "./ClientEdit.modal.tsx";

const ClientEdit = withSuspense(lazy(() => import("./ClientEdit.modal.tsx")));

export const useEditClientModal = createModalHook<ClientsEditProps>((props) => () => (
  <ClientEdit {...props} />
));
