import dayjs from "dayjs";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import("dayjs/locale/en");
import("dayjs/locale/ru");

export const LocalesProvider = ({ children }: PropsWithChildren) => {
  const { i18n } = useTranslation();
  dayjs.locale(i18n.language);

  return <>{children}</>;
};
