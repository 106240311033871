import { FieldSkeleton } from "../fieldSkeleton";
import css from "./ProfileSkeletonFields.module.scss";

const ProfileSkeletonFields = () => {
  return (
    <div className={css.container}>
      <div className={css.fieldsContainer}>
        {[...new Array(4)].map((_, idx) => (
          <FieldSkeleton key={idx} />
        ))}
      </div>
      <div className={css.buttons}>
        <div className={css.submit} />
        <div className={css.cancel} />
      </div>
    </div>
  );
};
export default ProfileSkeletonFields;
