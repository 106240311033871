import { useTheme } from "@app/providers/ThemeProvider.tsx";
import MoonIcon from "@shared/assets/icons/theme/moon.svg";
import SunIcon from "@shared/assets/icons/theme/sun.svg";
import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import { Box, Toggle, Typography } from "@shared/ui";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import css from "./ChangeTheme.module.scss";

const ChangeThemeComponent = () => {
  const { t } = useTranslation();
  const { darkMode, toggleDarkMode } = useTheme();
  const isMobile = useMediaQuery("Mobile");

  if (isMobile) {
    return (
      <Box
        direction="row"
        alignItems="center"
        justifyContent="between"
        className={css.mobileWrapper}
        onClick={toggleDarkMode}
      >
        <Box direction="row" alignItems="center" className="gap-[8px]">
          {!darkMode ? <SunIcon /> : <MoonIcon className={css.svg} />}
          <Typography variant="body2">{t("dark-mode")}</Typography>
        </Box>
        <Toggle checked={darkMode} />
      </Box>
    );
  }

  return (
    <div onClick={toggleDarkMode} className={cn(css.toggle, { [css.rotate]: !darkMode })}>
      {!darkMode ? <SunIcon /> : <MoonIcon className={css.svg} />}
    </div>
  );
};

export default ChangeThemeComponent;
