import { withSuspense } from "@shared/lib/hocs/withSuspense.hoc.tsx";
import { createModalHook } from "@shared/ui";
import { lazy } from "react";

import { ExportModalProps } from "./Export.modal.tsx";

const Export = withSuspense(lazy(() => import("./Export.modal.tsx")));

export const useExportReportModal = createModalHook<ExportModalProps>((props) => () => (
  <Export {...props} />
));
