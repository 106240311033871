import "./styles/global.scss";

import { Providers } from "@app/providers";
import { Router } from "@app/providers/Router.tsx";
import { ModalContainer } from "@shared/ui";

import { BundleLoading } from "./providers/BundleLoading.tsx";
import { ToastContainer } from "./providers/ToastContainer";

export const App = () => {
  return (
    <Providers>
      <BundleLoading />
      <ModalContainer />
      <Router />
      <ToastContainer />
    </Providers>
  );
};
