import { useTranslation } from "react-i18next";

import { CustomToastVariants } from "./CustomToast.constant.ts";
import css from "./styles/CustomToast.module.scss";

export const useCustomToast = () => {
  const { t } = useTranslation();

  const toastVariants = {
    [CustomToastVariants.ERROR]: {
      title: t("toast.error"),
      styles: css.error,
    },
    [CustomToastVariants.INFO]: {
      title: t("toast.info"),
      styles: css.info,
    },
    [CustomToastVariants.SUCCESS]: {
      title: t("toast.success"),
      styles: css.success,
    },
    [CustomToastVariants.WARNING]: {
      title: t("toast.warning"),
      styles: css.warning,
    },
  };

  return { toastVariants };
};
