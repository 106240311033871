import { Box, LayoutTheme, Typography } from "@shared/ui";
import { useTranslation } from "react-i18next";

import {
  ProfileSkeletonChangePassword,
  ProfileSkeletonFields,
  ProfileSkeletonMainContainer,
} from "./components";

const ProfileSkeletonContainer = () => {
  const { t } = useTranslation();

  return (
    <Box gapY="md">
      <Typography variant="h2" fontWeight="medium">
        {t("account")}
      </Typography>
      <LayoutTheme padding="none">
        <ProfileSkeletonMainContainer />
        <ProfileSkeletonFields />
      </LayoutTheme>
      <LayoutTheme>
        <ProfileSkeletonChangePassword />
      </LayoutTheme>
    </Box>
  );
};

export default ProfileSkeletonContainer;
