import { AppStorage } from "@shared/constants/appStorage.constant.ts";
import { AppTheme } from "@shared/constants/appTheme.constant.ts";
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";

interface ThemeContextProps {
  darkMode: boolean;
  metaColor: string;
  theme: AppTheme;
  charts: {
    primaryColor: string;
    textColor: string;
    doughnutTextColor: string;
  };
  toggleDarkMode: () => void;
}

const META_DARK_COLOR = "#25293c";
const META_LIGHT_COLOR = "#fff";
const CHARTS_PRIMARY_COLOR = "#5C52C0";
const TEXT_DARK_COLOR = "#CFD3EC";
const TEXT_LIGHT_COLOR = "#4B465C";
const BLACK_COLOR = "#000";

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem(AppStorage.THEME);
    return savedTheme === AppTheme.DARK;
  });

  useEffect(() => {
    const currentTheme = darkMode ? AppTheme.DARK : AppTheme.LIGHT;

    localStorage.setItem(AppStorage.THEME, currentTheme);
    document.body.className = currentTheme;
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  };

  const theme = darkMode ? AppTheme.DARK : AppTheme.LIGHT;

  const contextValue = useMemo(
    () => ({
      darkMode,
      toggleDarkMode,
      theme,
      metaColor: darkMode ? META_DARK_COLOR : META_LIGHT_COLOR,
      charts: {
        primaryColor: CHARTS_PRIMARY_COLOR,
        textColor: darkMode ? TEXT_DARK_COLOR : TEXT_LIGHT_COLOR,
        doughnutTextColor: darkMode ? META_DARK_COLOR : BLACK_COLOR,
      },
    }),
    [darkMode, theme],
  );

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
