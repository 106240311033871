import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useUserRole } from "@shared/lib/hooks/useUser.ts";
import { Navigate, Outlet } from "react-router-dom";

interface CheckRoleProps {
  allowedRoles: AppRole[];
}

export const CheckRole = ({ allowedRoles }: CheckRoleProps) => {
  const userRole = useUserRole();
  const isAllowed = allowedRoles.find((role) => role === userRole);

  if (isAllowed) {
    return <Outlet />;
  }

  return <Navigate to={!userRole ? AppRoutes.LOGIN : AppRoutes.CLIENT_ERROR} />;
};
