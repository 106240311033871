import { withSuspense } from "@shared/lib/hocs/withSuspense.hoc.tsx";
import { parseError } from "@shared/utils/parseError.ts";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider as TanstackQueryClientProvider,
} from "@tanstack/react-query";
import { lazy, PropsWithChildren } from "react";

const ReactQueryDevtoolsProduction = withSuspense(
  lazy(() =>
    import("@tanstack/react-query-devtools/build/modern/production.js").then((d) => ({
      default: d.ReactQueryDevtools,
    })),
  ),
);

const DEFAULT_STALE_TIME = 120000; // 2 min
const SHOW_DEV_TOOLS = IS_DEV === "true";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: DEFAULT_STALE_TIME,
    },
    mutations: {
      onError: (e) => {
        parseError(e);
      },
    },
  },
  queryCache: new QueryCache({
    onError: (e) => {
      parseError(e);
    },
  }),
});

export const QueryClientProvider = ({ children }: PropsWithChildren) => {
  return (
    <TanstackQueryClientProvider client={queryClient}>
      {children}
      {SHOW_DEV_TOOLS && (
        <ReactQueryDevtoolsProduction initialIsOpen={false} buttonPosition="bottom-left" />
      )}
    </TanstackQueryClientProvider>
  );
};
