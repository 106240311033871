import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { FirebaseEvent } from "@shared/constants/firebase/firebaseEvent.constant";
import { useFirebase } from "@shared/lib/hooks/useFirebase.ts";
import cn from "classnames";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

import { Typography } from "../../typography";
import { InputProps } from "./input.interface.ts";
import css from "./Input.module.scss";

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      error,
      type = "text",
      className,
      linkTitle = "",
      title = "",
      style,
      required,
      ...rest
    },
    ref,
  ) => {
    const { logEventToFirebase } = useFirebase();

    const forgotPasswordOnClick = () => {
      logEventToFirebase(FirebaseEvent.LOG_IN_FORGOT_PASSWORD);
    };

    return (
      <div className={cn(css.common, className)}>
        <div>
          <Typography variant="body4" truncate>
            {title} {required && "*"}
          </Typography>
          {linkTitle && (
            <Link
              to={AppRoutes.FORGOT_PASSWORD}
              onClick={forgotPasswordOnClick}
              className="text-link"
            >
              {linkTitle}
            </Link>
          )}
        </div>
        <div className={cn(css.field, { [css.fieldError]: error })} style={style}>
          <label>
            <input ref={ref} type={type} {...rest} placeholder={placeholder} />
          </label>
        </div>
        {error && <div className={css.error}>{error.message}</div>}
      </div>
    );
  },
);

export default Input;
