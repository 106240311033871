import { useIsSidebarOpen } from "@shared/store/sidebar";
import cn from "classnames";

import { useSidebarLinks } from "../../useSidebarLinks.tsx";
import { MenuItem } from "../menuItem";
import css from "./Menu.module.scss";

interface MenuProps {
  onClose?: () => void;
}
const Menu = ({ onClose }: MenuProps) => {
  const sideBarLinks = useSidebarLinks();
  const isOpen = useIsSidebarOpen();

  const onCloseModal = () => {
    if (onClose) onClose();
  };

  return (
    <nav>
      <ul className={cn(css.ul, { [css.closedSidebar]: !isOpen })}>
        {sideBarLinks.map((item) => (
          <MenuItem
            key={item.link}
            className="max-lg:border-bottom-primary"
            {...item}
            onClose={onCloseModal}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
