export function groupBy<T, K extends keyof T>(
  list: T[],
  keyGetter: (item: T) => T[K],
): Map<T[K], T[]> {
  const map = new Map<T[K], T[]>();

  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return Object.fromEntries(map);
}
