import { PageListProps } from "@components/pagesList/PageList.types.ts";
import { Box, Typography } from "@shared/ui";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

import css from "./PagesList.module.scss";

const PagesList = ({ items }: PageListProps) => {
  const { pathname } = useLocation();

  return (
    <Box direction="row" alignItems="center" fullWidth className={css.container}>
      <ul className={css.list}>
        {items.map(({ title, icon, path }) => (
          <li key={path}>
            <Link
              to={path}
              className={cn(css.item, {
                [css.active]: path === decodeURIComponent(pathname) || pathname.includes(path),
              })}
            >
              <Box>{icon}</Box>
              <Typography
                variant="body2"
                truncate
                className={cn(css.title, {
                  [css.active]: path === decodeURIComponent(pathname) || pathname.includes(path),
                })}
              >
                {title}
              </Typography>
            </Link>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default PagesList;
