import { useCallback, useEffect, useState } from "react";

export enum MediaType {
  MobileSmall = "xs",
  Mobile = "sm",
  MobileLarge = "md",
  Tablet = "lg",
  Laptop = "xl",
  LargeLaptop = "xxl",
}

const Width: Record<MediaType, number> = {
  xs: 380,
  sm: 568,
  md: 850,
  lg: 1025,
  xl: 1280,
  xxl: 1600,
};

export const useMediaQuery = (type: keyof typeof MediaType) => {
  const [matched, setMatched] = useState(false);

  const handleMediaChange = useCallback((e: MediaQueryListEvent | MediaQueryList) => {
    setMatched(e.matches);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${Width[MediaType[type]]}px)`);

    handleMediaChange(mediaQuery);

    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [type, handleMediaChange]);

  return matched;
};
