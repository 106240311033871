import { getImage } from "@shared/ui/image/getImage.ts";
import { ImageProps } from "@shared/ui/image/Image.types.ts";
import cn from "classnames";
import { ForwardedRef, forwardRef, SyntheticEvent, useState } from "react";

import ErrorIcon from "../../assets/icons/notFound/imageNotFound.png";
import css from "./Image.module.scss";

export const Image = forwardRef(
  ({ src = "", className, alt, ...props }: ImageProps, ref: ForwardedRef<HTMLImageElement>) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleError = (e: SyntheticEvent<HTMLImageElement>) => {
      e.currentTarget.src = ErrorIcon;
    };

    const handleLoad = () => {
      setIsLoading(false);
    };

    return (
      <>
        <img
          ref={ref}
          src={getImage(src)}
          className={cn(className, { [css.hiddenIcon]: isLoading })}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          {...props}
        />
        {isLoading && <div className={cn(css.skeleton, className)} />}
      </>
    );
  },
);

Image.displayName = "Image";
