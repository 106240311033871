import { AppLanguage } from "@shared/constants/appLanguage.constant.ts";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const supportedLngs = Object.values(AppLanguage);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: AppLanguage.RUSSIAN,
    debug: IS_DEV === "true",
    interpolation: {
      escapeValue: false,
    },
    supportedLngs,
    defaultNS: "translation",
    ns: "translations",
    load: "all",
  });

export default i18n;
