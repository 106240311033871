import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import { useSidebarActions } from "@shared/store/sidebar";
import { useEffect } from "react";

import { DesktopNavigation } from "./components/desktopNavigation";
import { TabletNavigation } from "./components/tabletNavigation";

interface NavigationProps {
  onClose?: () => void;
}
export const Navigation = ({ onClose }: NavigationProps) => {
  const isTablet = useMediaQuery("Tablet");
  const { onOpen } = useSidebarActions();

  useEffect(() => {
    if (isTablet) {
      onOpen();
    }
  }, [isTablet, onOpen]);

  if (isTablet) {
    return <TabletNavigation onClose={onClose} />;
  }

  return <DesktopNavigation />;
};
