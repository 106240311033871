import ArrowUpIcon from "@shared/assets/icons/chevrons/chevronUp.svg";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { useUserRole } from "@shared/lib/hooks/useUser.ts";
import { useIsSidebarOpen, useSidebarActions } from "@shared/store/sidebar";
import cn from "classnames";
import { useState } from "react";

import { useSidebarLinksPopup } from "../../useSidebarLinks.tsx";
import { MenuItem } from "../menuItem";
import css from "./MenuPopup.module.scss";

interface MenuPopupProps {
  onClose?: () => void;
}
export const MenuPopup = ({ onClose }: MenuPopupProps) => {
  const role = useUserRole();
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const { onOpen } = useSidebarActions();
  const isOpen = useIsSidebarOpen();
  const { nested, items } = useSidebarLinksPopup();

  const togglePopup = () => {
    if (!isOpen) {
      onOpen();
      setIsPopupOpen(true);
    } else {
      setIsPopupOpen((prev) => !prev);
    }
  };

  if (role === AppRole.DIRECTOR) {
    return null;
  }
  const onCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <nav>
      <ul
        onClick={togglePopup}
        className={cn(css.toggleList, {
          [css.openedList]: isPopupOpen,
          [css.closedSidebar]: !isOpen,
        })}
      >
        {items.map((item) => (
          <MenuItem key={item.link} type="toggle" {...item} />
        ))}
        <li>
          {role !== AppRole.ADMIN_SUPPORT && (
            <ArrowUpIcon className={cn(css.svg, { [css.openArrow]: !isPopupOpen || !isOpen })} />
          )}
        </li>
      </ul>
      {isPopupOpen && isOpen && (
        <ul className={cn(css.nestedList, { [css.openedList]: isPopupOpen })}>
          {nested?.map((item) => (
            <MenuItem key={item.link} type="nested" {...item} onClose={onCloseModal} />
          ))}
        </ul>
      )}
    </nav>
  );
};
