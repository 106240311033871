import LoaderIcon from "@shared/assets/icons/loader/loader.svg";

import css from "./Loader.module.scss";

const Loader = () => {
  return (
    <div className={css.container}>
      <LoaderIcon />
    </div>
  );
};

export default Loader;
