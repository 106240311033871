import {
  StatusClient,
  StatusCustomer,
  StatusEquipment,
  StatusShop,
  StatusUser,
} from "@shared/types/statuses.interface";
import { StatusRequest } from "@shared/types/statuses.interface.ts";

export const statusVariants = {
  [StatusClient.connected]: "text-green bg-green",
  [StatusClient.archived]: "text-silver-500 bg-silver-500",
  [StatusClient.disconnected]: "text-red bg-red",
  [StatusClient.on_service]: "text-gray-800 bg-primary-500",
  [StatusShop.pending]: "text-orange bg-orange",
  [StatusEquipment.working]: "text-green bg-green",
  [StatusEquipment.not_working]: "text-red bg-red",
  [StatusUser.on_break]: "text-orange bg-orange",
  [StatusUser.vacationing]: "text-orange bg-orange",
  [StatusUser.blocked]: "text-red bg-red",
  [StatusRequest.new]: "text-orange bg-orange",
  [StatusRequest.processing]: "text-gray-800 bg-primary-500",
  [StatusRequest.approved]: "text-green bg-green",
  [StatusRequest.rejected]: "text-red bg-red",
  [StatusCustomer.active]: "text-green bg-green",
  [StatusCustomer.returned]: "text-green bg-green",
  [StatusCustomer.inactive]: "text-red bg-red",
  [StatusCustomer.removed]: "text-red bg-red",
};
