import SidebarArrowIcon from "@shared/assets/icons/chevrons/chevronRight.svg";
import LogoSmallIcon from "@shared/assets/icons/logo/logo.svg";
import LogoLargeIcon from "@shared/assets/icons/logo/logoWithText.svg";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { AppStorage } from "@shared/constants/appStorage.constant.ts";
import { useUserRole } from "@shared/lib/hooks/useUser.ts";
import { useRetailBannerQuery } from "@shared/services/retail";
import { useRetailId } from "@shared/store/retail";
import { useIsSidebarOpen, useSidebarActions } from "@shared/store/sidebar";
import { Box, Image } from "@shared/ui";
import cn from "classnames";
import { Link } from "react-router-dom";

import css from "./LogoSection.module.scss";

const LogoSection = () => {
  const role = useUserRole();
  const { onToggle } = useSidebarActions();
  const isOpen = useIsSidebarOpen();
  const retailId = useRetailId();
  const { data } = useRetailBannerQuery(Number(retailId));

  const onToggleSidebar = () => {
    localStorage.setItem(AppStorage.SIDEBAR_CLOSED, String(isOpen));
    onToggle();
  };

  return (
    <Box
      className={cn(css.wrapperLogo, { [css.closedWrapperLogo]: !isOpen })}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      <Link to={AppRoutes.HOME} className={cn(css.logo, { [css.closedLogo]: !isOpen })}>
        {role === AppRole.DIRECTOR ? (
          <Image src={data?.logo} alt="logo" className={css.imageContainer} />
        ) : (
          <Box direction="row" alignItems="center" className={css.logoContainer}>
            {isOpen && <LogoLargeIcon className={css.logoSvg} />}
            {!isOpen && <LogoSmallIcon className={css.logoSvg} />}
          </Box>
        )}
      </Link>
      <Box
        className={cn(css.arrowWrapper, { [css.closedArrowWrapper]: !isOpen })}
        onClick={onToggleSidebar}
      >
        <SidebarArrowIcon className={cn(css.logoSvg, { [css.activeSidebarArrow]: isOpen })} />
      </Box>
    </Box>
  );
};

export default LogoSection;
