import { AvatarHeader } from "@components/header/components/avatarHeader";
import { MobileSettings } from "@components/header/components/mobileSettings";
import { Navigation } from "@components/navigation";
import HeaderRuleIcon from "@shared/assets/icons/headerRule.svg";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { Box, createModalHook, ModalOptions } from "@shared/ui";
import { useTranslation } from "react-i18next";

import css from "./BurgerModal.module.scss";

interface BurgerProps extends ModalOptions {
  isMobile: boolean;
  role?: AppRole;
}

const Burger = ({ isMobile, role, onClose }: BurgerProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isMobile && <AvatarHeader label={role && t(role)} hasButton={isMobile} onClose={onClose} />}
      <Navigation onClose={onClose} />
      {isMobile && <MobileSettings />}
      <Box
        direction="row"
        justifyContent="center"
        alignItems="end"
        className="flex-1 pt-4"
        fullWidth
      >
        <HeaderRuleIcon className={css.svg} />
      </Box>
    </>
  );
};

export default createModalHook<BurgerProps>((props) => () => <Burger {...props} />);
