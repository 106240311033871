import { ChangeLanguage } from "@components/changeLanguage";
import { ChangeTheme } from "@components/changeTheme";
import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import { Box } from "@shared/ui";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { HeaderProps } from "../../Header.types.ts";
import { AvatarHeader } from "../avatarHeader";
import { BurgerMenu } from "../burgerMenu";
import { Notifications } from "../notifications";
import css from "./TabletHeader.module.scss";

const TabletHeaderComponent = ({ role }: HeaderProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("Mobile");

  return (
    <header className={cn(css.wrapper)}>
      <Box direction="row" justifyContent="between" alignItems="center" fullWidth fullHeight>
        <AvatarHeader label={!isMobile ? role && t(role) : ""} />
        <Box direction="row" alignItems="center" className="gap-[16px]">
          {!isMobile && <ChangeLanguage />}
          {!isMobile && <ChangeTheme />}
          <Notifications />
          <BurgerMenu role={role} />
        </Box>
      </Box>
    </header>
  );
};

export default TabletHeaderComponent;
