import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSlice } from "@shared/lib/hooks/useSlice.ts";

import { TypeRootState } from "../index.ts";
import { InitialState } from "./modal.types.ts";

const initialState: InitialState = { data: [] };

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    addModal: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    removeModal: (state, action: PayloadAction<{ id: string }>) => {
      state.data = state.data.filter((item) => item.id !== action.payload.id);
    },
  },
});

export const { reducer: modalReducer } = modalSlice;
export const modalSelector = (state: TypeRootState) => state.modal;

export const useModalActions = () => useSlice(modalSlice);
