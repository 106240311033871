import { AppRoutes } from "@shared/constants/appRoutes.constant.ts";
import { useIsAuth } from "@shared/lib/hooks/useUser.ts";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  isNonePrivate?: boolean;
}

export const ProtectedRoute = ({ isNonePrivate }: ProtectedRouteProps) => {
  const isAuth = useIsAuth();

  if (isNonePrivate && isAuth) {
    return <Navigate to={AppRoutes.HOME} />;
  }

  if (!isNonePrivate && !isAuth) {
    return <Navigate to={AppRoutes.LOGIN} />;
  }

  return <Outlet />;
};
