import { ProfileSkeletonContainer } from "@components/skeleton";
import { withSuspense } from "@shared/lib/hocs/withSuspense.hoc.tsx";
import { lazy } from "react";

const FallBack = () => <ProfileSkeletonContainer />;

export const Profile = withSuspense(
  lazy(async () => import("./Profile.page.tsx")),
  FallBack,
);
