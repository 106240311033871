import { Box } from "@shared/ui";

import { Menu } from "../menu";
import { MenuPopup } from "../menuPopup";

interface TabletNavigationProps {
  onClose?: () => void;
}
const TabletNavigation = ({ onClose }: TabletNavigationProps) => {
  return (
    <Box>
      <MenuPopup onClose={onClose} />
      <Menu onClose={onClose} />
    </Box>
  );
};

export default TabletNavigation;
