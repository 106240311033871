export const decodeJwtToken = (token?: string) => {
  if (!token) {
    throw new Error("Token is undefined");
  }

  const base64UrlPayload = token.split(".")[1];
  const base64Payload = base64UrlPayload.replace(/-/g, "+").replace(/_/g, "/");
  const decodedPayload = atob(base64Payload);
  const parsedPayload = JSON.parse(decodedPayload);

  return parsedPayload;
};
