import { createSlice } from "@reduxjs/toolkit";
import { AppStorage } from "@shared/constants/appStorage.constant.ts";
import { useSlice } from "@shared/lib/hooks/useSlice.ts";

import { InitialState } from "./sidebar.types.ts";

const hasSidebarValue = Boolean(localStorage.getItem(AppStorage.SIDEBAR_CLOSED));
const defaultIsOpen = localStorage.getItem(AppStorage.SIDEBAR_CLOSED) === "false";

const initialState: InitialState = {
  isOpen: hasSidebarValue ? defaultIsOpen : true,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    onOpen: (state) => {
      state.isOpen = true;
    },
    onClose: (state) => {
      state.isOpen = false;
    },
    onToggle: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { actions: sidebarActions, reducer: sidebarReducer } = sidebarSlice;

export const useSidebarActions = () => useSlice(sidebarSlice);
export * from "./sidebar.hook.ts";
