import { CardInformation, CardInformationProps } from "@components/cardInformation";
import { useEditLocationModal } from "@components/modals/location";
import { FirebaseEvent } from "@shared/constants/firebase/firebaseEvent.constant.ts";
import { useFirebase } from "@shared/lib/hooks/useFirebase.ts";
import { useTranslation } from "react-i18next";

interface ShopCardInformationProps extends Omit<CardInformationProps, "onEdit" | "type"> {
  shopId: number;
  retailId: number;
}

const ShopCardInformationComponent = ({
  shopId,
  retailId,
  person,
  ...props
}: ShopCardInformationProps) => {
  const { t } = useTranslation();
  const { logEventToFirebase } = useFirebase();
  const [openEditModal] = useEditLocationModal();

  const onEdit = () => {
    openEditModal({
      directorId: Number(person?.director_id),
      retailId,
      shopId,
      title: t("edit-location", { number: shopId }),
    });
    logEventToFirebase(FirebaseEvent.RETAILS_EDIT);
  };

  return <CardInformation onEdit={onEdit} type="shop" person={person} shopId={shopId} {...props} />;
};

export default ShopCardInformationComponent;
