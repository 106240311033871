import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSlice } from "@shared/lib/hooks/useSlice.ts";
import { User } from "@shared/services/users";

import { InitialState } from "./user.types.ts";

const initialState: InitialState = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, user: PayloadAction<User | undefined>) => {
      if (user.payload) {
        state.user = user.payload;
      }
    },
    clearUser: (state) => {
      state.user = undefined;
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;

export const useUserActions = () => useSlice(userSlice);
