import { TableProviderProps } from "@shared/types/table.interface";
import { createContext, useContext } from "react";

const TableContext = createContext({});

export const useTableContext = <T, J>() => {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error("useTableContext must be used within a TableProvider");
  }

  return context as TableProviderProps<T, J>;
};

export const TableProvider = <T, J>({ ...props }: TableProviderProps<T, J>) => {
  return <TableContext.Provider value={{ ...props }}>{props.children}</TableContext.Provider>;
};
