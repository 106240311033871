import css from "./FieldSkeleton.module.scss";

const FieldSkeleton = () => {
  return (
    <div className={css.field}>
      <div className={css.text} />
      <div className={css.input} />
    </div>
  );
};

export default FieldSkeleton;
