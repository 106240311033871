import { useModalActions } from "@shared/store/modal";
import { FC, memo, ReactNode, useCallback, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { ErrorBoundary, ErrorModal } from "../errorBoundary";

const generateUuid = () => {
  return uuidv4();
};
type ModalVariants = "left" | "right" | "center" | "alert" | "notifications";

export interface ModalOptions {
  onClose: () => void;
  variant?: ModalVariants;
  title?: string;
  customHeader?: ReactNode;
}

export const createModalHook = <T extends ModalOptions>(component: (props: T) => FC<unknown>) =>
  function useModal(): [(props?: Omit<T, "onClose">) => void, () => void] {
    const { addModal, removeModal } = useModalActions();
    const idRef = useRef<string>();

    const onClose = useCallback(() => {
      if (idRef.current) {
        removeModal({ id: idRef.current });
      }
    }, [removeModal]);

    const onCreate = useCallback(
      (props: Omit<T, "onClose"> = {} as T) => {
        const id = generateUuid();
        idRef.current = id;

        const Component = memo(component({ ...props, onClose } as T));

        addModal({
          variant: props.variant || "right",
          id,
          title: props.title,
          component: () => (
            <ErrorBoundary fallback={<ErrorModal />}>
              <Component />
            </ErrorBoundary>
          ),
          ...props,
        });
      },
      [addModal, onClose],
    );

    return [onCreate, onClose];
  };
