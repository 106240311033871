import { PropsWithChildren } from "react";

import { AuthProvider } from "./AuthProvider/AuthProvider.tsx";
import { FirebaseProvider } from "./FirebaseProvider";
import { HelmetProvider } from "./HelmetProvider";
import { IntercomProvider } from "./IntercomProvider";
import { LocalesProvider } from "./LocalesProvider.tsx";
import { QueryClientProvider } from "./QueryClientProvider.tsx";
import { ScrollProvider } from "./ScrollProvider";
import { SentryClientProvider } from "./SentryProvider";
import { StoreProvider } from "./StoreProvider.tsx";
import { ThemeProvider } from "./ThemeProvider.tsx";

export const Providers = ({ children }: PropsWithChildren) => {
  return (
    <SentryClientProvider>
      <HelmetProvider>
        <StoreProvider>
          <QueryClientProvider>
            <FirebaseProvider>
              <ThemeProvider>
                <LocalesProvider>
                  <IntercomProvider>
                    <AuthProvider>
                      <ScrollProvider>{children}</ScrollProvider>
                    </AuthProvider>
                  </IntercomProvider>
                </LocalesProvider>
              </ThemeProvider>
            </FirebaseProvider>
          </QueryClientProvider>
        </StoreProvider>
      </HelmetProvider>
    </SentryClientProvider>
  );
};
