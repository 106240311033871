export type QueryObject<T> = Record<keyof T, string>;

export const parseObjToQueryParams = <T extends {}>(obj: T) => {
  return Object.entries(obj)
    .filter(([, value]) => value)
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: String(value),
      };
    }, {} as QueryObject<T>);
};
