import { AppCookie } from "@shared/constants/appCookies.constant.ts";
import { Tokens } from "@shared/store/user/user.types.ts";
import { decodeJwtToken } from "@shared/utils/decodeJwt.ts";
import Cookies from "js-cookie";

export const saveTokensToCookieStorage = (tokens: Tokens) => {
  const expTime = new Date(decodeJwtToken(tokens.access)?.exp * 1000);
  Cookies.set(AppCookie.ACCESS, tokens.access, { expires: expTime });
  Cookies.set(AppCookie.REFRESH, tokens.refresh, { expires: expTime });
};

export const removeTokensFromCookieStorage = () => {
  Cookies.remove(AppCookie.ACCESS);
  Cookies.remove(AppCookie.REFRESH);
  sessionStorage.clear();
};

export const saveTokensToSessionStorage = (tokens: Tokens) => {
  sessionStorage.setItem(AppCookie.ACCESS, tokens.access);
  sessionStorage.setItem(AppCookie.REFRESH, tokens.refresh);
};
