import { configureStore } from "@reduxjs/toolkit";

import { contentReducer } from "./content";
import { modalReducer } from "./modal";
import { retailReducer } from "./retail";
import { sidebarReducer } from "./sidebar";
import { userReducer } from "./user";

const reducers = {
  user: userReducer,
  modal: modalReducer,
  retail: retailReducer,
  sidebar: sidebarReducer,
  content: contentReducer,
};

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: IS_DEV === "true",
});

export type TypeRootState = ReturnType<typeof store.getState>;
