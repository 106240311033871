export enum QueryTag {
  SHOPS = "get all shops",
  GET_CLIENTS = "get all clients",
  GET_CLIENTS_BANNER_INFO = "get clients banner info",
  GET_CLIENTS_BY_ID = "get clients by id",
  GET_REQUESTS = "get all requests",
  GET_REQUESTS_BY_ID = "get requests by id",
  GET_EQUIPMENT = "get equipment",
  GET_EQUIPMENT_BY_ID = "get equipment by id",
  GET_EQUIPMENT_STATUS_LIST = "get equipment status list",
  GET_RETAIL_STAFF = "get retail staff",
  GET_SHOP_STAFF = "get shop staff",
  GET_RETAIL_STAFF_BY_ID = "get retail staff by id",
  GET_SHOPS_LIST = "get list of shops for person",
  GET_SHOP_BY_ID = "get shop by id",
  GET_RETAIL_BANNER_INFO = "get retail banner info",
  GET_EQUIPMENT_IN_SHOP = "get equipment in shop",
  GET_EQUIPMENT_IN_SHOP_BY_ID = "get equipment in shop by id",
  GET_SHOP_BANNER_INFO = "get shop banner info",
  GET_RETAIL_STATISTICS = "get retail statistics",
  GET_SHOP_STATISTICS = "get shop statistics",
  GET_RETAIL_CHART = "get retail chart",
  GET_SHOP_CHART_STATISTICS = "get shop chart statistics",
  GET_SHOP_SANKEY_STATISTICS = "get shop sankey statistics",
  GET_USERS = "get users",
  GET_USERS_CARD_INFO = "get users card info",
  GET_USERS_BY_ID = "get users by id",
  GET_VISITS_IN_SHOP = "get visits in shop",
  GET_PERSONS_STATISTICS = "get persons statistics",
  GET_DOCUMENTS = "get documents",
  GET_PROFILE_INFO = "get profile info",
  GET_USER_ME = "get user me",
  GET_CUSTOMERS_SUMMARY = "get customers summary",
  GET_CUSTOMERS = "get customers",
  GET_CUSTOMER = "get customer",
  GET_CUSTOMER_STATISTIC = "get customer statistic",
  GET_CUSTOMER_VISITS = "get customer visits",
  GET_STATISTICS_REPORT = "get statistics report",
  GET_ANALYTICS_SUMMARY = "get analytics summary",
  GET_ANALYTICS_CHART = "get analytics chart",
  GET_ANALYTICS_FLOWCHART = "get analytics flowchart",
  GET_ANALYTICS_SHOPS = "get analytics shops",
  GET_CUSTOMER_RETAIL_VISITS = "get customer retail visits",
}
