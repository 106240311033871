import AdminIcon from "@shared/assets/icons/dashboard/roles/admin.svg";
import ManagerIcon from "@shared/assets/icons/dashboard/roles/manager.svg";
import SupportIcon from "@shared/assets/icons/dashboard/roles/support.svg";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { useTranslation } from "react-i18next";

import { Box } from "../../../box";
import { OverflowTooltip } from "../../../tooltip";
import css from "./TableRole.module.scss";

interface TableStatus {
  type?: Exclude<AppRole, AppRole.SUPER_ADMIN | AppRole.SUPPORT>;
}

const roleVariantsIcon = {
  [AppRole.SUPER_ADMIN]: <AdminIcon />,
  [AppRole.ADMIN]: <AdminIcon />,
  [AppRole.ADMIN_MANAGER]: <ManagerIcon />,
  [AppRole.ADMIN_SUPPORT]: <SupportIcon />,
  [AppRole.SUPPORT]: <SupportIcon />,
  [AppRole.DIRECTOR]: <AdminIcon />,
  [AppRole.MANAGER]: <ManagerIcon />,
  [AppRole.CONSULTANT]: <SupportIcon />,
};
const roleVariantsColor = {
  [AppRole.SUPER_ADMIN]: css.orange,
  [AppRole.ADMIN]: css.orange,
  [AppRole.ADMIN_MANAGER]: css.purple,
  [AppRole.ADMIN_SUPPORT]: css.green,
  [AppRole.SUPPORT]: css.green,
  [AppRole.DIRECTOR]: css.orange,
  [AppRole.MANAGER]: css.purple,
  [AppRole.CONSULTANT]: css.green,
};

const TableRoleComponent = ({ type }: TableStatus) => {
  const { t } = useTranslation();
  return (
    type && (
      <Box
        direction="row"
        justifyContent="start"
        alignItems="center"
        className={css.container}
        fullWidth
      >
        <div className={roleVariantsColor[type]}>{roleVariantsIcon[type]}</div>
        <OverflowTooltip content={t(type)}>
          <p className={css.text}>{t(type)}</p>
        </OverflowTooltip>
      </Box>
    )
  );
};

export default TableRoleComponent;
