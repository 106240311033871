import { withSuspense } from "@shared/lib/hocs/withSuspense.hoc.tsx";
import { createModalHook } from "@shared/ui";
import { lazy } from "react";

import { LocationCreateProps } from "./create/LocationCreate.modal.tsx";
import { LocationEditProps } from "./edit/LocationEdit.modal.tsx";

const LocationCreate = withSuspense(lazy(() => import("./create/LocationCreate.modal.tsx")));
const LocationEdit = withSuspense(lazy(() => import("./edit/LocationEdit.modal.tsx")));

export const useCreateLocationModal = createModalHook<LocationCreateProps>((props) => () => (
  <LocationCreate {...props} />
));

export const useEditLocationModal = createModalHook<LocationEditProps>((props) => () => (
  <LocationEdit {...props} />
));
