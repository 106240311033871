import EyeCloseIcon from "@shared/assets/icons/actions/eyeClose.svg";
import EyeOpenIcon from "@shared/assets/icons/actions/eyeOpen.svg";
import { forwardRef, useState } from "react";

import { Box } from "../../box";
import { Button } from "../../button";
import { Input } from "../input";
import { InputProps } from "../input/input.interface.ts";
import css from "./PasswordInput.module.scss";

const PasswordInputComponent = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [isShow, setIsShow] = useState(false);

  const onToggle = () => {
    setIsShow((prev) => !prev);
  };

  return (
    <Box className={css.inputContainer} fullWidth>
      <Input
        ref={ref}
        className={css.input}
        type={isShow ? "text" : "password"}
        placeholder="············"
        {...props}
      />
      <Button className={css.button} onClick={onToggle}>
        {isShow && <EyeOpenIcon />}
        {!isShow && <EyeCloseIcon />}
      </Button>
    </Box>
  );
});

export default PasswordInputComponent;
