import EquipmentIcon from "@shared/assets/icons/pageList/equipments.svg";
import PersonsIcon from "@shared/assets/icons/pageList/persons.svg";
import LocationsIcon from "@shared/assets/icons/pageList/shops.svg";
import StatisticsIcon from "@shared/assets/icons/pageList/statistics.svg";
import { useTranslation } from "react-i18next";

interface ClientItemsProps {
  clientId?: string;
}

export const useShopsLayout = ({ clientId }: ClientItemsProps) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("locations"),
      path: `/clients/${clientId}/locations`,
      icon: <LocationsIcon />,
    },
    {
      title: t("statistics-locations"),
      path: `/clients/${clientId}/statistics`,
      icon: <StatisticsIcon />,
    },
    {
      title: t("persons"),
      path: `/clients/${clientId}/persons`,
      icon: <PersonsIcon />,
    },
    {
      title: t("equipment-locations"),
      path: `/clients/${clientId}/equipments`,
      icon: <EquipmentIcon />,
    },
  ];

  return {
    items,
  };
};
