import { useUserRole } from "@shared/lib/hooks/useUser.ts";
import { useIsSidebarOpen } from "@shared/store/sidebar";
import { Typography } from "@shared/ui/index.ts";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";

import { Item } from "../../Navigation.types.ts";
import css from "./MenuItem.module.scss";

const styleByType = {
  nested: css.nestedItem,
  toggle: "",
  base: "",
};

export const MenuItem = ({ link, title, icon, roles, type = "base", className, onClose }: Item) => {
  const { pathname } = useLocation();
  const role = useUserRole();
  const isOpen = useIsSidebarOpen();

  const isActive = pathname === link || (pathname.includes(link) && link !== "/");

  if (role && !roles.includes(role)) {
    return null;
  }

  return (
    <li
      className={cn(
        css.li,
        {
          [css.active]: isActive && type !== "toggle",
          [css.toggleItem]: type === "toggle",
        },
        className,
      )}
      onClick={onClose}
    >
      <Link
        to={link}
        className={cn(css.link, styleByType[type], {
          [css.closedSidebar]: !isOpen && type !== "base",
        })}
      >
        {icon}
        {isOpen && (
          <Typography variant="body2" color={isActive && type !== "toggle" ? "white" : "primary"}>
            {title}
          </Typography>
        )}
      </Link>
    </li>
  );
};
