import css from "./DesktopSkeletonMain.module.scss";

const DesktopSkeletonMain = () => {
  return (
    <div className={css.container}>
      <div className={css.headerContainer}>
        <div className={css.header} />
        <div className={css.logout} />
      </div>
      <div className={css.profileInfo}>
        <div className={css.avatar} />
        <div className={css.block}>
          <div className={css.notificationContainer}>
            <div className={css.uploadBtn} />
            <div className={css.notification} />
          </div>
          <div className={css.description} />
        </div>
      </div>
    </div>
  );
};

export default DesktopSkeletonMain;
