import { statusVariants } from "@shared/constants/statusVariants.constants.ts";
import { StatusVariant } from "@shared/types/statuses.interface.ts";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { OverflowTooltip } from "../../../tooltip";
import css from "./TableStatus.module.scss";

const sizesVariants = {
  primary: "py-[5px] px-[10px]",
  secondary: "px-[20px] py-[10px]",
  middle: "py-[7px] px-[18px]",
};

interface TableStatusProps {
  status: StatusVariant;
  sizeType?: "primary" | "secondary";
  fullWidth?: boolean;
  className?: string;
}

const TableStatusComponent = ({
  status,
  sizeType = "primary",
  fullWidth,
  className,
}: TableStatusProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        css.container,
        fullWidth && "w-full text-center",
        sizesVariants[sizeType],
        statusVariants[status],
        className,
      )}
    >
      <OverflowTooltip content={t(status)}>
        <p className={cn(statusVariants[status], css.status)}>{t(status)}</p>
      </OverflowTooltip>
    </div>
  );
};

export default TableStatusComponent;
