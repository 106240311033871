import EquipmentIcon from "@shared/assets/icons/pageList/equipments.svg";
import PersonsIcon from "@shared/assets/icons/pageList/persons.svg";
import StatisticsIcon from "@shared/assets/icons/pageList/statistics.svg";
import { useTranslation } from "react-i18next";

interface LocationItemsProps {
  clientId?: number | string;
  locationId?: number | string;
}

export const useLocationItems = ({ clientId, locationId }: LocationItemsProps) => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("statistics-location"),
      path: `/clients/${clientId}/locations/${locationId}/statistic`,
      icon: <StatisticsIcon />,
    },
    {
      title: t("persons-location"),
      path: `/clients/${clientId}/locations/${locationId}/persons`,
      icon: <PersonsIcon />,
    },
    {
      title: t("equipment-location"),
      path: `/clients/${clientId}/locations/${locationId}/equipment`,
      icon: <EquipmentIcon />,
    },
  ];

  return {
    items,
  };
};
