import cn from "classnames";
import * as React from "react";

import css from "./Button.module.scss";

const variants = {
  primary: css.primary,
  cancel: css.cancel,
  danger: css.danger,
  secondary: css.secondary,
  transparent: css.transparent,
  logout: css.logout,
  close: css.close,
  alert: css.alert,
  alertSubmit: css.alertSubmit,
};
export type buttonVariants = keyof typeof variants;

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.PropsWithChildren<{
    fullWidth?: boolean;
    fullHeight?: boolean;
    variant?: buttonVariants;
    noPadding?: boolean;
  }>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      disabled,
      className = "",
      children,
      fullWidth,
      fullHeight,
      noPadding,
      ...props
    },
    ref,
  ) => {
    const padding = noPadding ? 0 : undefined;

    return (
      <button
        ref={ref}
        className={cn(
          css.button,
          "btn-primary",
          variant && variants[variant],
          disabled && "opacity-30",
          fullHeight && "h-full",
          fullWidth && "w-full",
          variant !== "close" && css.responsive,
          className,
        )}
        style={{ padding }}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    );
  },
);
