import LanguageIcon from "@shared/assets/icons/world.svg";
import { AppLanguage } from "@shared/constants/appLanguage.constant.ts";
import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import { Box, Typography } from "@shared/ui";
import { useTranslation } from "react-i18next";

import css from "./ChangeLanguage.module.scss";

const LANGUAGES = {
  [AppLanguage.ENGLISH]: { main: "EN", secondary: "ru", value: AppLanguage.RUSSIAN },
  [AppLanguage.RUSSIAN]: { main: "RU", secondary: "en", value: AppLanguage.ENGLISH },
};

const ChangeLanguageComponent = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const currentLanguage = LANGUAGES[language as AppLanguage];
  const isMobile = useMediaQuery("Mobile");

  const renderLanguage = () => {
    return (
      <div className={css.container} onClick={handleLanguage(currentLanguage.value)}>
        <span>{currentLanguage.main}</span>
        <span>/</span>
        <span>{currentLanguage.secondary}</span>
      </div>
    );
  };

  const handleLanguage = (language: string) => () => {
    changeLanguage(language);
  };

  if (!isMobile) {
    return renderLanguage();
  }

  return (
    <Box
      onClick={handleLanguage(currentLanguage.value)}
      direction="row"
      alignItems="center"
      justifyContent="between"
      className={css.mobileWrapper}
    >
      <Box direction="row" alignItems="center" className="gap-[8px]">
        <LanguageIcon className="svg-primary" />
        <Typography variant="body2">{t("language")}</Typography>
      </Box>
      {renderLanguage()}
    </Box>
  );
};

export default ChangeLanguageComponent;
