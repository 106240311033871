import css from "./MobileSkeletonMain.module.scss";

const MobileSkeletonMain = () => {
  return (
    <div className={css.container}>
      <div className={css.profileInfo}>
        <div className={css.header} />
        <div className={css.avatar} />
        <div className={css.description} />
      </div>
      <div className={css.buttons}>
        <div className={css.uploadBtn} />
        <div className={css.logout} />
      </div>
      <div className={css.notificationContainer}>
        <div className={css.notification} />
      </div>
    </div>
  );
};

export default MobileSkeletonMain;
