import AvatarNotFoundIcon from "@shared/assets/icons/avatar/avatarNotFound.svg";
import cn from "classnames";

import { Box } from "../box";
import { Image } from "../image";
import { Typography, Variants } from "../typography";
import css from "./EntityAvatar.module.scss";

interface EntityAvatarProps {
  name?: string;
  surname?: string;
  logo?: string | null;
  size?: Variants;
  isContain?: boolean;
  isBorder?: boolean;
  className?: string;
  isHeaderAvatar?: boolean;
}

export const EntityAvatar = ({
  name,
  surname,
  logo,
  size,
  isHeaderAvatar,
  isContain,
  isBorder,
  className,
}: EntityAvatarProps) => {
  if (isHeaderAvatar && !logo) {
    return <AvatarNotFoundIcon className="size-full" />;
  }

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      className={isBorder ? css.wrapperWithBorder : css.wrapper}
    >
      {logo && (
        <Image
          src={logo}
          alt="IconLogo"
          className={cn(css.image, isContain ? "object-contain" : "object-cover", className)}
        />
      )}
      {!logo && (name || surname) && (
        <Typography variant={size} fontWeight="normal">
          {name?.[0]}
          {surname?.[0]}
        </Typography>
      )}
    </Box>
  );
};
