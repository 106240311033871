import { ComponentType, FC, Suspense } from "react";

import { ErrorBoundary } from "../../ui/errorBoundary";

const FallBackDefault = () => <div></div>;

export const withSuspense =
  <T extends {}>(
    Component: ComponentType<T>,
    FallBackComponent: ComponentType = FallBackDefault,
  ): FC<T> =>
  (props: T) => (
    <ErrorBoundary>
      <Suspense fallback={<FallBackComponent />}>
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  );
