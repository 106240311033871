import cn from "classnames";
import { forwardRef } from "react";

import { LayoutThemeProps } from "./LayoutTheme.types";

export const alignItemsClsx = {
  center: "items-center",
  start: "items-start",
  end: "items-end",
  stretch: "items-stretch",
};

export const justifyContentClsx = {
  center: "justify-center",
  start: "justify-start",
  end: "justify-end",
  between: "justify-between",
  around: "justify-around",
  evenly: "justify-evenly",
};
export const directions = {
  column: "flex flex-col",
  row: "flex",
  "column-reverse": "flex flex-col-reverse",
};

export const paddings = {
  none: "p-0",
  xs: "p-1",
  sm: "p-2",
  m: "p-3",
  md: "p-4",
  lg: "p-5",
  x: "p-6",
};

export const LayoutTheme = forwardRef<HTMLDivElement, LayoutThemeProps>(
  (
    {
      direction = "column",
      padding = "x",
      className = "",
      justifyContent,
      alignItems,
      children,
      fullWidth,
      fullHeight,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          "border-box bg-above-body shadow-layout rounded-layout",
          padding && paddings[padding],
          fullHeight && "h-full",
          fullWidth && "w-full",
          justifyContent && justifyContentClsx[justifyContent],
          alignItems && alignItemsClsx[alignItems],
          directions[direction],
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);

LayoutTheme.displayName = "LayoutTheme";
