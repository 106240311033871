import { Component, ErrorInfo, ReactNode } from "react";

import { CustomToastVariants, showToast } from "../toast";
import { ErrorPage } from "./ErrorPage.component.tsx";

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    showToast(error.message, CustomToastVariants.ERROR);
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <ErrorPage />;
    }
    return this.props.children;
  }
}
