import { useEffect } from "react";

export const BundleLoading = () => {
  useEffect(() => {
    const loading = document.getElementById("loading");
    if (loading) {
      loading.style.display = "none";
    }
  }, []);

  return null;
};
