import { Box } from "@shared/ui";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import css from "./BreadCrumbs.module.scss";

interface BreadcrumbsProps {
  dataTestId?: string;
  ignoredSegments?: number[];
  lastSegmentName?: string;
}

const Breadcrumbs = ({ ignoredSegments, lastSegmentName }: BreadcrumbsProps) => {
  const { t } = useTranslation();

  const location = useLocation();
  const segments = location.pathname.split("/").filter(Boolean);

  return (
    <Box direction="row" className={css.breadcrumbs}>
      {segments.map((path, index) => {
        if (ignoredSegments?.includes(index)) {
          return null;
        }

        const str =
          index === segments.length - 1 && lastSegmentName
            ? lastSegmentName
            : decodeURIComponent(t(`${path as "clients"}`));

        return (
          <Box direction="row" key={index} className={css.crumb}>
            <Link to={`/${segments.slice(0, index + 1).join("/")}`}>{str}</Link>
            {index < segments.length - 1 && <span className={css.separator}>/</span>}
          </Box>
        );
      })}
    </Box>
  );
};
export default Breadcrumbs;
