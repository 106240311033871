import { useTheme } from "@app/providers/ThemeProvider.tsx";
import { useLocale } from "@shared/lib/hooks/useLocale.ts";
import { User } from "@shared/services/users";
import { useIntercom } from "react-use-intercom";

export const useIntercomSession = () => {
  const { boot, shutdown } = useIntercom();
  const { metaColor } = useTheme();
  const { lang } = useLocale();

  const onConnect = (user: User) => {
    boot({
      userId: String(user.id),
      email: user.email,
      name: user.name,
      phone: user.phone,
      backgroundColor: metaColor,
      actionColor: "#5C52C0",
      languageOverride: lang,
    });
  };

  const onDisconnect = () => {
    shutdown();
  };

  return { onConnect, onDisconnect };
};
