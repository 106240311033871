import { Breadcrumbs } from "@components/breadCrumbs";
import { PagesList } from "@components/pagesList";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { useUserRole } from "@shared/lib/hooks/useUser.ts";
import { useRetailBannerQuery } from "@shared/services/retail";
import { Box } from "@shared/ui";
import { Outlet, useParams } from "react-router-dom";

import { RetailCardInformation } from "./components/retailCardInformation";
import { useShopsLayout } from "./layout.hook.tsx";
import css from "./layout.module.scss";

export const Layout = () => {
  const { clientId } = useParams();

  const role = useUserRole();

  const { isLoading, data } = useRetailBannerQuery(Number(clientId));
  const { items } = useShopsLayout({ clientId });

  const ignoredSegments = role === AppRole.DIRECTOR ? [0, 1, 3] : [1, 3];

  return (
    <Box className={css.container}>
      <Breadcrumbs ignoredSegments={ignoredSegments} />
      {role !== AppRole.DIRECTOR && <PagesList items={items} />}
      <RetailCardInformation retailId={Number(clientId)} person={data} isLoading={isLoading} />
      <Outlet context={[data?.director_id]} />
    </Box>
  );
};
