import { ErrorResponse } from "@shared/types/global.interface.ts";
import { CustomToastVariants, showToast } from "@shared/ui/toast";
import { AxiosError } from "axios";
import { FieldPath, FieldValues, UseFormSetError } from "react-hook-form";

const VALIDATION_ERROR = "validation_error";

interface Options<T extends FieldValues> {
  handleCustomMessage?: () => void;
  setError?: UseFormSetError<T>;
}

type ErrorAttribute<T extends FieldValues> = FieldPath<T> | `root.${string}` | "root";

export const parseError = <T extends FieldValues>(e: unknown, options?: Options<T>) => {
  const error = e as AxiosError<ErrorResponse<ErrorAttribute<T>>>;

  if (error.response?.data.type === VALIDATION_ERROR && options?.setError) {
    error.response.data.errors.forEach(({ attr, detail }) => {
      options.setError?.(attr, { message: detail });
    });
    return;
  }

  const errorMessage = error.response?.data?.errors?.[0]?.detail;

  if (errorMessage) {
    showToast(errorMessage, CustomToastVariants.ERROR);
  }

  if (options?.handleCustomMessage && !errorMessage) {
    options.handleCustomMessage();
  }
};
