import "./styles/CustomToast.styles.scss";

import BellToastIcon from "@shared/assets/icons/toast/bellToast.svg";

import { Box } from "../box";
import { Typography } from "../typography";
import { useCustomToast } from "./CustomToast.hook.ts";
import { CustomToastProps } from "./CustomToast.types.ts";
import css from "./styles/CustomToast.module.scss";

export const CustomToast = ({ message, type }: CustomToastProps) => {
  const { toastVariants } = useCustomToast();
  return (
    <Box className={css.container}>
      <Box direction="row" alignItems="center" className={css.header}>
        <BellToastIcon className={toastVariants[type]?.styles} />
        <Typography variant="body2bold" className={css.typography}>
          {toastVariants[type]?.title}
        </Typography>
      </Box>
      <Box direction="row" alignItems="center" justifyContent="between" className={css.block}>
        <Typography variant="body3">{message}</Typography>
      </Box>
    </Box>
  );
};
