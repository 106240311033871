import cn from "classnames";
import { forwardRef, HTMLAttributes } from "react";

export type BoxProps = HTMLAttributes<HTMLDivElement> & {
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  direction?: Directions;
  fullWidth?: boolean;
  fullHeight?: boolean;
  truncate?: boolean;
  paddingX?: BaseSizes;
  paddingY?: BaseSizes;
  gapX?: BaseSizes;
  gapY?: BaseSizes;
  marginX?: BaseSizes;
  marginY?: BaseSizes;
};

type BaseSizes = keyof typeof baseSizes;
type JustifyContent = keyof typeof justifyContentClsx;
type AlignItems = keyof typeof alignItemsClsx;
type Directions = keyof typeof directions;

const baseSizes = {
  xs: "1",
  sm: "2",
  m: "3",
  md: "4",
  lg: "5",
  x: "6",
};

const justifyContentClsx = {
  center: "justify-center",
  start: "justify-start",
  end: "justify-end",
  between: "justify-between",
  around: "justify-around",
  evenly: "justify-evenly",
};

const alignItemsClsx = {
  center: "items-center",
  start: "items-start",
  end: "items-end",
  stretch: "items-stretch",
};

const directions = {
  column: "flex flex-col",
  row: "flex",
  "row-reverse": "flex flex-row-reverse",
  "column-reverse": "flex flex-col-reverse",
};

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  (
    {
      className = "",
      children,
      justifyContent,
      alignItems,
      direction = "column",
      fullWidth,
      fullHeight,
      truncate,
      paddingX,
      paddingY,
      gapX,
      gapY,
      marginX,
      marginY,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          className,
          justifyContent && justifyContentClsx[justifyContent],
          alignItems && alignItemsClsx[alignItems],
          directions[direction],
          fullWidth && "w-full",
          fullHeight && "h-full",
          truncate && "truncate",
          paddingX && `px-${baseSizes[paddingX]}`,
          paddingY && `py-${baseSizes[paddingY]}`,
          gapX && `gap-${baseSizes[gapX]}`,
          gapY && `gap-${baseSizes[gapY]}`,
          marginX && `mx-${baseSizes[marginX]}`,
          marginY && `my-${baseSizes[marginY]}`,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);

Box.displayName = "Box";
