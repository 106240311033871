export enum FirebaseEvent {
  LOG_IN_SIGN_IN = "log_in_sign_in",
  LOG_IN_FORGOT_PASSWORD = "log_in_forg_pass",
  LOG_IN_SEND_RESET_LINK = "log_in_send_reset_link",
  LOGOUT = "logout",
  SAVE_DOCUMENT = "save_document",
  STORES_ADD_SUBMIT = "stores_add_submit",
  RETAILS_ADD_SUBMIT = "retails_add_submit",
  ROLES_ADD_SUBMIT = "roles_add_submit",
  STORES_PERSONS_ADD_SUBMIT = "stores_persons_add_submit",
  STORE_EQUIPMENT_ADD_SUBMIT = "store_equipment_add_submit",
  STORES_EDIT_SUBMIT = "stores_edit_submit",
  STORES_PERSONS_EDIT_SUBMIT = "stores_persons_edit_submit",
  STORES_EQUIPMENTS_EDIT_SUBMIT = "stores_equipments_edit_submit",
  RETAILS_EDIT_SUBMIT = "retails_edit_submit",
  ROLES_EDIT_SUBMIT = "roles_edit_submit",
  REQUESTS_EDIT_SUBMIT = "requests_edit_submit",
  STORE_EQUIPMENT_EDIT_SUBMIT = "store_equipment_edit_submit",
  STORES_REMOVE = "stores_remove",
  STORES_PERSONS_REMOVE = "stores_persons_remove",
  STORE_PERSONS_REMOVE = "store_persons_remove",
  STORES_EQUIPMENTS_REMOVE = "stores_equipments_remove",
  ROLES_REMOVE = "roles_remove",
  STORE_EQUIPMENT_REMOVE = "store_equipment_remove",
  UBO_PROFILE_UPDATE_PHOTO = "ubo_profile_update_photo",
  CHANGE_PROFILE_INFO = "change_profile_info",
  CHANGE_PROFILE_PASSWORD = "change_profile_password",
  PROFILE_REMOVE = "profile_remove",
  SWITCH_STATUS = "switch_status",
  DASHBOARD_SEARCH = "search",
  SELECT_PAGE = "select_page",
  STORE_SEARCH_STAFF = "search_staff",
  CHANGE_COUNT = "change_count",
  CHANGE_DATE = "change_date",
  USER_ROLE = "role",
  USER_STATUS = "status",
  STORES_SELECT_STORE = "stores_select_store",
  FIREBASE_SCREEN = "firebase_screen",
  FIREBASE_SCREEN_CLASS = "firebase_screen_class",
  STORES_ADD = "stores_add",
  RETAILS_ADD = "retails_add",
  ROLES_ADD = "roles_add",
  STORES_PERSONS_ADD = "stores_persons_add",
  STORE_PERSONS_ADD = "store_persons_add",
  STORE_EQUIPMENT_ADD = "store_equipment_add",
  STORES_EDIT = "stores_edit",
  RETAILS_EDIT = "retails_edit",
  ROLES_EDIT = "roles_edit",
  REQUESTS_EDIT = "requests_edit",
  STORES_PERSONS_EDIT = "stores_persons_edit",
  STORE_PERSONS_EDIT = "store_persons_edit",
  STORE_EQUIPMENT_EDIT = "store_equipment_edit",
  STORES_EQUIPMENT_EDIT = "stores_equipment_edit",
  STORE_EQUIPMENT_INFO = "store_equipment_info",
  STORE_PERSONS_INFO = "store_persons_info",
  STORES_PERSONS_INFO = "stores_persons_info",
  STORES_EQUIPMENT_INFO = "stores_equipment_info",
  ROLES_INFO = "roles_info",
}
