import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";

import { DesktopSkeletonMain } from "./desktop";
import { MobileSkeletonMain } from "./mobile";

const ProfileSkeletonMainContainer = () => {
  const isMobile = useMediaQuery("MobileLarge");

  if (isMobile) {
    return <MobileSkeletonMain />;
  }

  return <DesktopSkeletonMain />;
};

export default ProfileSkeletonMainContainer;
