import { AppCookie } from "@shared/constants/appCookies.constant.ts";
import { useUserMeQuery } from "@shared/services/auth";
import { Loader } from "@shared/ui";
import Cookies from "js-cookie";
import { PropsWithChildren, useEffect, useState } from "react";

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { refetch } = useUserMeQuery();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accessTokenFromCookie = Cookies.get(AppCookie.ACCESS);
    const accessTokenFromSession = sessionStorage.getItem(AppCookie.ACCESS);
    const accessToken = accessTokenFromCookie || accessTokenFromSession;

    if (accessToken) {
      refetch().finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [refetch]);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return <>{children}</>;
};
