import { Breadcrumbs } from "@components/breadCrumbs";
import { PagesList } from "@components/pagesList";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { useCurrentRole } from "@shared/lib/hooks/useUser.ts";
import { useClientByIdQuery } from "@shared/services/clients";
import { Box, Meta } from "@shared/ui";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

import { useAnalyticsItems } from "./layout.hook.tsx";

export const Layout = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();

  const isDirector = useCurrentRole(AppRole.DIRECTOR);

  const { items } = useAnalyticsItems(clientId);
  const { data } = useClientByIdQuery({ clientId: Number(clientId) });
  const ignoredSegments = [1];

  return (
    <Meta title={t("analytics")}>
      <Box gapY="md">
        {!isDirector && (
          <Breadcrumbs
            ignoredSegments={ignoredSegments}
            lastSegmentName={data?.retail_network_name}
          />
        )}
        <Box direction="row" alignItems="center" justifyContent="between">
          <PagesList items={items} />
        </Box>
        <Outlet />
      </Box>
    </Meta>
  );
};
