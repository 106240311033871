export enum AppRole {
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
  ADMIN_MANAGER = "admin_manager",
  ADMIN_SUPPORT = "admin_support",
  DIRECTOR = "director",
  MANAGER = "manager",
  CONSULTANT = "consultant",
  SUPPORT = "support",
}
