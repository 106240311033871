import CheckedIcon from "@shared/assets/icons/checkbox/check.svg";
import CrossIcon from "@shared/assets/icons/checkbox/crossCheck.svg";
import cn from "classnames";
import { forwardRef, InputHTMLAttributes } from "react";

import { Typography } from "../typography";
import css from "./CheckBox.module.scss";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  isCross?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, checked, labelClassName, isCross = false, ...props }, ref) => {
    return (
      <div>
        <label className={css.wrapper}>
          <div
            className={cn(css.input, {
              [css.activeInput]: checked && !isCross,
              [css.defaultCrossInput]: !checked && isCross,
            })}
          >
            <input
              className={css.hiddenInput}
              type="checkbox"
              checked={checked}
              ref={ref}
              {...props}
            />
            {checked && !isCross && <CheckedIcon className={css.icon} />}
            {isCross && (
              <CrossIcon
                className={cn(css.icon, css.crossIcon, { [css.crossIconActive]: checked })}
              />
            )}
          </div>
          {label && (
            <Typography variant="body2" className={cn(css.text, labelClassName)}>
              {label}
            </Typography>
          )}
        </label>
      </div>
    );
  },
);
