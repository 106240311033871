import { useScrollContext } from "@app/providers/ScrollProvider/ScrollProvider.tsx";
import ChevronsUpIcon from "@shared/assets/icons/chevrons/chevronsUp.svg";
import { useIsContentScrolled } from "@shared/store/content";
import cn from "classnames";

import css from "./ScrollButton.module.scss";

const ScrollButton = () => {
  const { onScrollToTop } = useScrollContext();
  const isScrolled = useIsContentScrolled();

  return (
    <button className={cn(css.button, { [css.scrollButton]: isScrolled })} onClick={onScrollToTop}>
      <ChevronsUpIcon />
    </button>
  );
};

export default ScrollButton;
