import { useContentActions } from "@shared/store/content";
import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollContextProps {
  onScrollToTop: () => void;
}

interface ScrollProviderProps extends PropsWithChildren {}

const ScrollContext = createContext<ScrollContextProps | null>(null);

export const useScrollContext = () => {
  const context = useContext(ScrollContext);

  if (!context) {
    throw new Error("useScrollContext must be used within a ScrollProvider");
  }

  return context;
};

const ScrollProvider = ({ children }: ScrollProviderProps) => {
  const location = useLocation();
  const { onScroll } = useContentActions();

  const onScrollToTop = () => {
    const pageWrapper = document.getElementById("pageWrapper");
    if (pageWrapper) {
      pageWrapper.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      onScroll(false);
    }
  };

  useEffect(() => {
    onScrollToTop();
  }, [location.pathname]);

  return <ScrollContext.Provider value={{ onScrollToTop }}>{children}</ScrollContext.Provider>;
};

export default ScrollProvider;
