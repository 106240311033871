import LogoLargeIcon from "@shared/assets/icons/logo/logoErrorLarge.svg";
import cn from "classnames";
import { t } from "i18next";

import { Box } from "../box";
import { Typography } from "../typography";
import css from "./ErrorBoundary.module.scss";

export const ErrorPage = () => {
  return (
    <Box alignItems="center" justifyContent="center" className={cn(css.errorComponent)}>
      <LogoLargeIcon />
      <Box alignItems="center" justifyContent="center" className={css.typographyContainer}>
        <Typography variant="h1">{t("error-boundary.title")}</Typography>
        <Typography variant="body2">{t("error-boundary.description")}</Typography>
      </Box>
    </Box>
  );
};
