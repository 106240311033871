import { Breadcrumbs } from "@components/breadCrumbs";
import { PagesList } from "@components/pagesList";
import { ShopCardInformation } from "@pages/shop/components/shopCardInformation";
import { AppRole } from "@shared/constants/appRoles.constant.ts";
import { useUserRole } from "@shared/lib/hooks/useUser.ts";
import { useShopBannerQuery } from "@shared/services/shop";
import { Box } from "@shared/ui";
import { Outlet, useParams } from "react-router-dom";

import { useLocationItems } from "./layout.hook.tsx";
import css from "./layout.module.scss";

export const Layout = () => {
  const role = useUserRole();

  const { clientId, locationId } = useParams();

  const { items } = useLocationItems({ clientId, locationId });
  const { data, isLoading } = useShopBannerQuery({ id: Number(locationId) });

  const ignoredSegments = role === AppRole.DIRECTOR ? [0, 1, 3] : [1, 3];

  return (
    <Box className={css.container}>
      <Breadcrumbs ignoredSegments={ignoredSegments} />
      {role !== AppRole.DIRECTOR && <PagesList items={items} />}
      <ShopCardInformation
        shopId={Number(locationId)}
        person={data}
        retailId={Number(clientId)}
        isLoading={isLoading}
      />
      <Outlet context={[data?.director_id]} />
    </Box>
  );
};
