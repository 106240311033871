import { ToastOptions, TypeOptions } from "react-toastify";

import { CustomToast } from "./CustomToast.component.tsx";

const showToast = async (message: string, type: TypeOptions, options?: ToastOptions) => {
  const { toast } = await import("react-toastify");

  const customToast = <CustomToast message={message} type={type} />;
  const baseOptions = {
    ...options,
    autoClose: 5000,
  };

  const toastTypes = {
    info: toast.info,
    success: toast.success,
    warning: toast.warning,
    error: toast.error,
    default: toast,
  };

  const toastFunction = toastTypes[type] || toast;
  toastFunction(customToast, baseOptions);
};

export default showToast;
