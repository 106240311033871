import { Box } from "@shared/ui";

import css from "./CardSkeleton.module.scss";

const CardSkeleton = () => {
  return (
    <Box className={css.wrapper} direction="row" alignItems="center" justifyContent="between">
      <Box direction="row" alignItems="center" className={css.companyContainer}>
        <Box direction="row" alignItems="center" className={css.avatarContainer}>
          <div className={css.avatar} />
          <Box className="gap-2">
            <div className={css.rowBig} />
            <div className={css.rowLittle} />
          </Box>
        </Box>
        <div className={css.btn} />
      </Box>
      <Box className={css.informationContainer}>
        <Box className="gap-1.5" fullWidth>
          <div className={css.textLittle} />
          <div className={css.textBig} />
        </Box>
        <Box className="gap-1.5" fullWidth>
          <div className={css.textLittle} />
          <div className={css.textBig} />
        </Box>
      </Box>
    </Box>
  );
};

export default CardSkeleton;
