import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSlice } from "@shared/lib/hooks/useSlice.ts";

import { InitialState } from "./content.types.ts";

const initialState: InitialState = {
  isScrolled: false,
  isDisabled: false,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    onScroll: (state, payload: PayloadAction<boolean>) => {
      state.isScrolled = payload.payload;
    },
    onDisabled: (state, payload: PayloadAction<boolean>) => {
      state.isDisabled = payload.payload;
    },
  },
});

export const { actions: contentActions, reducer: contentReducer } = contentSlice;

export const useContentActions = () => useSlice(contentSlice);
export * from "./content.hook.ts";
