export enum StatusRequest {
  new = "new",
  processing = "processing",
  approved = "approved",
  rejected = "rejected",
}

export enum StatusClient {
  connected = "connected",
  disconnected = "disconnected",
  on_service = "on_service",
  archived = "archived",
  pending = "pending",
}

export enum StatusShop {
  connected = "connected",
  disconnected = "disconnected",
  on_service = "on_service",
  archived = "archived",
  pending = "pending",
}

export enum StatusUser {
  working = "working",
  not_working = "not_working",
  vacationing = "vacationing",
  on_break = "on_break",
  archived = "archived",
  blocked = "blocked",
}

export enum StatusEquipment {
  working = "working",
  not_working = "not_working",
  archived = "archived",
}

export enum StatusCustomer {
  active = "active",
  inactive = "inactive",
  removed = "removed",
  returned = "returned",
}

export enum TypesRequest {
  connectedRetail = "connected_retail",
  connectedShop = "connected_shop",
  disconnectedRetail = "disconnected_retail",
  disconnectedShop = "disconnected_shop",
}

export enum TitleType {
  Camera = "camera",
  Tablet = "tablet",
  Software = "software",
  Server = "server",
  CashRegister = "cash_register",
  Other = "other",
}

export enum TypeEquipments {
  camera = "camera",
  tablet = "tablet",
  server = "server",
  software = "software",
  cashRegister = "cash_register",
  other = "other",
}

export enum RequestsType {
  CONNECTED_RETAIL = "connected_retail",
  CONNECTED_SHOP = "connected_shop",
  DISCONNECTED_RETAIL = "disconnected_retail",
  DISCONNECTED_SHOP = "disconnected_shop",
}

export interface StatusControl {
  count: number;
  status: string | number;
}

export type StatusVariant =
  | StatusClient
  | StatusEquipment
  | StatusShop
  | StatusUser
  | StatusRequest
  | StatusCustomer;
