import { useMediaQuery } from "@shared/lib/hooks/useMediaQuery.ts";
import { useUserRole } from "@shared/lib/hooks/useUser.ts";

import { DesktopHeader } from "./components/desktopHeader";
import { TabletHeader } from "./components/tabletHeader";

export const Header = () => {
  const role = useUserRole();

  const isTablet = useMediaQuery("Tablet");

  if (isTablet) {
    return <TabletHeader role={role} />;
  }

  return <DesktopHeader role={role} />;
};
