import LogoSmallIcon from "@shared/assets/icons/logo/logoErrorSmall.svg";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { Box } from "../box";
import { Typography } from "../typography";
import css from "./ErrorBoundary.module.scss";

interface ErrorComponentProps {
  className?: string;
}

export const ErrorModal = ({ className }: ErrorComponentProps) => {
  const { t } = useTranslation();

  return (
    <Box alignItems="center" justifyContent="center" className={cn(css.errorComponent, className)}>
      <LogoSmallIcon />
      <Box alignItems="center" justifyContent="center" className={css.typographyContainer}>
        <Typography variant="h2" className={css.title}>
          {t("error-boundary.title")}
        </Typography>
        <Typography variant="body2">{t("error-boundary.description")}</Typography>
      </Box>
    </Box>
  );
};
