import { lazy, Suspense } from "react";

import { SingleSelectProps } from "../select.types.ts";

const LazySingleSelect = lazy(() => import("./SingleSelect.component.tsx"));

export const SingleSelect = <T,>(props: SingleSelectProps<T>) => {
  return (
    <Suspense fallback={<div />}>
      <LazySingleSelect {...props} />
    </Suspense>
  );
};
