export enum ApiUrl {
  LOGIN = "/admin/token",
  REFRESH = "/token/refresh",
  ADMIN = "/admin/",
  ADD_CLIENT = "/admin/add-client",
  REGISTER_USER = "/admin/register-user",
  CHANGE_PASSWORD = "/admin/change-password",
  USER_ME = "/users/me",
  USERS = "admin/users",
  USER_INFO = "/users/:id/info",
  STAFF_INFO_BY_ID = "/staff/:id/info",
  REGISTER_CONSULTANT = "/users/register/consultant",
  REGISTER_MANAGER = "/users/register/manager",
  REQUESTS = "/join-requests/",
  CREATE_REQUESTS = "/join-requests/create",
  REQUESTS_BY_ID = "/join-requests/:id/",
  RETAIL = "/retail-networks/",
  RETAIL_BY_ID = "/retail-networks/:id/",
  RETAIL_SHOPS = "/retail-networks/:id/shops",
  RETAIL_EQUIPMENT = "/retail-networks/:id/equipment",
  RETAIL_BANNER = "/retail-networks/:id/banner",
  SHOPS_EQUIPMENT = "/shops/:id/equipment",
  SHOP_INFO = "/shops/:id/info",
  SHOP_ADD = "/shops/add",
  SHOP_UPDATE = "/shops/:id/edit",
  SHOP_BANNER = "/shops/:id/banner",
  MANAGER_OR_CONSULTANT_PIN_SHOPS = "/staff/:id/pin-shops",
  EQUIPMENT = "/equipment/",
  EQUIPMENT_BY_ID = "/equipment/:id/",
  EQUIPMENT_STATUS_HISTORY = "/equipment/:id/status-changes/",
  STATISTICS = "/statistics",
  RETAIL_STATISTICS = "/statistics/retail-networks/:id",
  RETAIL_STATISTICS_CHART = "/statistics/retail-networks/:id/chart",
  SHOP_STATISTICS = "/shops/:id/statistics",
  SHOP_STATISTICS_CHART = "/statistics/shops/:id/chart",
  SHOP_STATISTICS_SANKEY = "/statistics/shops/:id/sankey",
  CONSULTANT_STATISTICS = "/statistics/consultants/:id",
  SHOP_STAFF = "/shops/:id/staff",
  RETAIL_STAFF = "/retail-networks/:id/staff",
  VISITS = "/shops/:id/visits",
  STAFF_SHOPS = "/staff/:id/shops",
  PERSON_STATISTICS_SHOP = "/statistics/consultants",
  RESET_PASSWORD = "/admin/reset-password",
  DOCUMENTS = "/documents",
  PRIVACY_POLICY = "/documents/:country/policy",
  CUSTOMERS_SUMMARY = "/customers/summary",
  CUSTOMERS = "/customers",
  CUSTOMER = "/customers/:id/detail-info",
  CUSTOMER_STATISTICS = "/customers/:id/statistics",
  CUSTOMER_VISITS = "/customers/:id/visits",
  CUSTOMER_RETAIL_VISITS = "/customers/:id/loyalty-cards",
  EXPORT_STATISTICS = "/statistics/retail-network/shops/report",
  GET_ANALYTICS_SUMMARY = "/statistics/retail-networks/shops/summary",
  GET_ANALYTICS_CHART = "/statistics/retail-networks/shops/chart",
  GET_ANALYTICS_SHOPS = "/statistics/retail-networks/shops",
  GET_ANALYTICS_FLOWCHART = "/statistics/retail-networks/shops/flowchart",
}
