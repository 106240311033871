import { ChangeLanguage } from "@components/changeLanguage";
import { ChangeTheme } from "@components/changeTheme";
import { useIsContentScrolled } from "@shared/store/content";
import { Box } from "@shared/ui";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { HeaderProps } from "../../Header.types.ts";
import { AvatarHeader } from "../avatarHeader";
import { Notifications } from "../notifications";
import css from "./DesktopHeader.module.scss";

const DesktopHeader = ({ role }: HeaderProps) => {
  const { t } = useTranslation();
  const isScrolled = useIsContentScrolled();

  return (
    <header className={cn(css.header, { [css.scrolledHeader]: isScrolled })}>
      <Box direction="row" justifyContent="end" alignItems="center" fullWidth fullHeight>
        <Box direction="row" alignItems="center" className={css.actions}>
          <ChangeLanguage />
          <ChangeTheme />
          <Notifications />
          <AvatarHeader label={role && t(role)} direction="row-reverse" />
        </Box>
      </Box>
    </header>
  );
};

export default DesktopHeader;
