import { FieldSkeleton } from "../fieldSkeleton";
import css from "./ProfileSkeletonChangePassword.module.scss";

const ProfileSkeletonChangePassword = () => {
  return (
    <div>
      <div className={css.header} />
      <div className={css.fieldsContainer}>
        {[...new Array(4)].map((_, idx) => (
          <FieldSkeleton key={idx} />
        ))}
      </div>
      <div className={css.requires}>
        <div className={css.title} />
        <div className={css.descriptionsContainer}>
          <div className={css.description} />
          <div className={css.description} />
          <div className={css.description} />
        </div>
      </div>
      <div className={css.buttons}>
        <div className={css.submit} />
        <div className={css.cancel} />
      </div>
    </div>
  );
};

export default ProfileSkeletonChangePassword;
